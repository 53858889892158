var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({staticClass:"btn btn-primary font-weight-bolder",attrs:{"href":"#"},on:{"click":_vm.refresh}},on),[_vm._v(" "+_vm._s(_vm.$t("synonyms.dialog_model.action.new_record")))])]}}]),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-title',[(this.model.id > 0)?_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t("synonyms.dialog_model.title.edit")))]):_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t("synonyms.dialog_model.title.add")))])]),_c('v-card-text',[_c('v-container',[(_vm.errors.length > 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"alert fade alert-danger",class:{ show: _vm.errors.length },attrs:{"role":"alert"}},_vm._l((_vm.errors),function(error,i){return _c('div',{key:i,staticClass:"alert-text"},[_vm._v(" "+_vm._s(error)+" ")])}),0)])],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"f_term",attrs:{"required":"","label":_vm.catalogTermLabel,"rules":[
                  function (v) { return !!v ||
                    this$1.$t('synonyms.dialog_model.fields.catalog_term.rule'); }
                ]},model:{value:(_vm.modelSynonym.term),callback:function ($$v) {_vm.$set(_vm.modelSynonym, "term", $$v)},expression:"modelSynonym.term"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.synonym_types,"item-text":"name","item-value":"key","label":_vm.typeLabel},model:{value:(_vm.modelSynonym.synonym_type_id),callback:function ($$v) {_vm.$set(_vm.modelSynonym, "synonym_type_id", $$v)},expression:"modelSynonym.synonym_type_id"}}),_c('p',{class:_vm.modelSynonym.synonym_type_id === '1'
                    ? 'text-primary font-weight-bold'
                    : ''},[_vm._v(" "+_vm._s(_vm.$t("synonyms.dialog_model.info1"))+" ")]),_c('p',{class:_vm.modelSynonym.synonym_type_id === '2'
                    ? 'text-primary font-weight-bold'
                    : ''},[_vm._v(" "+_vm._s(_vm.$t("synonyms.dialog_model.info2"))+" ")]),_c('p',{class:_vm.modelSynonym.synonym_type_id === '3'
                    ? 'text-primary font-weight-bold'
                    : ''},[_vm._v(" "+_vm._s(_vm.$t("synonyms.dialog_model.info3"))+" ")])],1)],1),_vm._l((_vm.modelSynonym.synonyms),function(synonym){
                    var this$1 = this;
return _c('v-row',{key:synonym.id},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{ref:"f_synonyms",refInFor:true,attrs:{"required":"","label":_vm.nameLabel,"rules":[
                  function (v) { return !!v || this$1.$t('synonyms.dialog_model.fields.name.rule'); }
                ]},model:{value:(synonym.name),callback:function ($$v) {_vm.$set(synonym, "name", $$v)},expression:"synonym.name"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"color":"error dark large","text":""},on:{"click":function($event){return _vm.removeValue(synonym.id)}}},[_vm._v(_vm._s(_vm.$t("synonyms.dialog_model.action.remove")))])],1)],1)}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"8"}},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.addValue}},[_vm._v(_vm._s(_vm.$t("synonyms.dialog_model.action.add_value")))])],1)],1)],2)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"darken-1","text":""},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v(_vm._s(_vm.$t("common.close"))+" ")]),_c('v-spacer'),_c('v-btn',{ref:"submit",attrs:{"color":"primary dark large"},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t("common.save"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }