<template>
  <v-dialog v-model="showDialog" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <a
        v-on="on"
        @click="refresh"
        href="#"
        class="btn btn-primary font-weight-bolder"
      >
        {{ $t("synonyms.dialog_model.action.new_record") }}</a
      >
    </template>
    <v-card>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-title>
          <span class="headline" v-if="this.model.id > 0">{{
            $t("synonyms.dialog_model.title.edit")
          }}</span>
          <span class="headline" v-else>{{
            $t("synonyms.dialog_model.title.add")
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row v-if="errors.length > 0">
              <v-col cols="12">
                <div
                  role="alert"
                  v-bind:class="{ show: errors.length }"
                  class="alert fade alert-danger"
                >
                  <div class="alert-text" v-for="(error, i) in errors" :key="i">
                    {{ error }}
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="modelSynonym.term"
                  required
                  :label="catalogTermLabel"
                  ref="f_term"
                  :rules="[
                    v =>
                      !!v ||
                      this.$t('synonyms.dialog_model.fields.catalog_term.rule')
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="modelSynonym.synonym_type_id"
                  :items="synonym_types"
                  item-text="name"
                  item-value="key"
                  :label="typeLabel"
                >
                </v-select>

                <p
                  :class="
                    modelSynonym.synonym_type_id === '1'
                      ? 'text-primary font-weight-bold'
                      : ''
                  "
                >
                  {{ $t("synonyms.dialog_model.info1") }}
                </p>
                <p
                  :class="
                    modelSynonym.synonym_type_id === '2'
                      ? 'text-primary font-weight-bold'
                      : ''
                  "
                >
                  {{ $t("synonyms.dialog_model.info2") }}
                </p>
                <p
                  :class="
                    modelSynonym.synonym_type_id === '3'
                      ? 'text-primary font-weight-bold'
                      : ''
                  "
                >
                  {{ $t("synonyms.dialog_model.info3") }}
                </p>
              </v-col>
            </v-row>
            <v-row v-for="synonym in modelSynonym.synonyms" :key="synonym.id">
              <v-col cols="8">
                <v-text-field
                  v-model="synonym.name"
                  required
                  :label="nameLabel"
                  ref="f_synonyms"
                  :rules="[
                    v =>
                      !!v || this.$t('synonyms.dialog_model.fields.name.rule')
                  ]"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-btn
                  color="error dark large"
                  @click="removeValue(synonym.id)"
                  text
                  >{{ $t("synonyms.dialog_model.action.remove") }}</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="8" md="8">
                <v-btn @click="addValue" text>{{
                  $t("synonyms.dialog_model.action.add_value")
                }}</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="darken-1" text @click="showDialog = false"
            >{{ $t("common.close") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary dark large" ref="submit" @click="save"
            >{{ $t("common.save") }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import { required } from "vuelidate/lib/validators";
import {
  REFRESH,
  POST_SYNONYMS,
  PULL_SYNONYMS,
  PUT_SYNONYM
} from "@/core/services/store/synonyms.module";
import { CLOSE_DIALOG } from "@/core/services/store/synonyms.module";

export default {
  data() {
    return {
      valid: true,
      dialog: false,
      synonym_types: [
        {
          key: "1",
          name: this.$t("synonyms.dialog_model.fields.type.select.option1")
        },
        {
          key: "2",
          name: this.$t("synonyms.dialog_model.fields.type.select.option2")
        },
        {
          key: "3",
          name: this.$t("synonyms.dialog_model.fields.type.select.option3")
        }
      ]
    };
  },
  methods: {
    refresh: function() {
      this.$store.dispatch(REFRESH);
    },
    addValue() {
      this.modelSynonym.synonyms.push({
        id: this.modelSynonym.synonyms.length + 1,
        name: ""
      });
    },
    removeValue: function(remIndex) {
      this.modelSynonym.synonyms.forEach(function(item, index, object) {
        if (item.id === remIndex) {
          object.splice(index, 1);
        }
      });
    },
    save() {
      if (this.$refs.form.validate()) {
        let synonyms = [];
        this.$refs.f_synonyms.forEach(function(item) {
          synonyms.push(item.value);
        });

        if (this.model.id > 0) {
          this.$store
            .dispatch(PUT_SYNONYM, {
              id: this.model.id,
              data: {
                term: this.$refs.f_term.value,
                synonyms: synonyms,
                synonym_type_id: this.model.synonym_type_id
              }
            })
            .then(() => {
              this.showDialog = false;
              this.$store.dispatch(REFRESH);
              this.$store.dispatch(PULL_SYNONYMS);
            });
        } else {
          this.$store
            .dispatch(POST_SYNONYMS, {
              term: this.$refs.f_term.value,
              synonyms: synonyms,
              synonym_type_id: this.model.synonym_type_id
            })
            .then(() => {
              this.showDialog = false;
              this.$store.dispatch(REFRESH);
              this.$store.dispatch(PULL_SYNONYMS);
            });
        }
      }
    },
    close() {
      this.$store.dispatch(CLOSE_DIALOG);
    }
  },
  validations: {
    form: {
      name: { required },
      type_id: { required }
    }
  },
  computed: {
    showDialog: {
      get: function() {
        return this.dialog || this.show_dialog;
      },
      set: function(value) {
        this.dialog = value;
        this.$store.dispatch(CLOSE_DIALOG);
      }
    },
    catalogTermLabel() {
      return this.$t("synonyms.dialog_model.fields.catalog_term.label");
    },
    typeLabel() {
      return this.$t("synonyms.dialog_model.fields.type.label");
    },
    nameLabel() {
      return this.$t("synonyms.dialog_model.fields.name.label");
    },
    ...mapState({
      errors: state => state.synonyms.errors,
      message: state => state.synonyms.message,
      hasMessage: state => state.synonyms.hasMessage,
      model: state => state.synonyms.model,
      show_dialog: state => state.synonyms.showDialog
    }),
    ...mapGetters(["modelSynonym"])
  },
  beforeCreate() {
    this.$store.dispatch(REFRESH);
  }
};
</script>
