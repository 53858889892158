<template>
  <div class="card card-custom gutter-b">
    <div class="card-header flex-wrap py-3">
      <div class="card-title">
        <h3 class="card-label">
          {{ $t("synonyms.title") }}
          <span class="d-block text-muted pt-2 font-size-sm">{{
            $t("synonyms.subtitle")
          }}</span>
        </h3>
      </div>
      <div class="card-toolbar">
        <!--begin::Button-->
        <edit_dialog />
        <!--end::Button-->
      </div>
    </div>
    <div class="card-body">
      <div
        id="kt_datatable_wrapper"
        class="dataTables_wrapper dt-bootstrap4 no-footer"
      >
        <div class="row">
          <div class="col-sm-12">
            <v-data-table
              dense
              :headers="headers"
              :items="items"
              :options.sync="options"
              :loading="loading"
              :search="search"
              :custom-filter="filterSearch"
              class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
            >
              <template v-slot:item.synonym_type_id="{ item }">
                {{ synonymRender(item) }}
              </template>
              <template v-slot:item.catalog_term="{ item }">
                <div class="badge badge-success">
                  {{ item.term }}
                </div>
              </template>
              <template v-slot:item.terms="{ item }">
                <div class="badge badge-danger">{{ item.synonyms }}</div>
              </template>
              <template v-slot:top>
                <div class="d-flex align-items-center position-relative my-1">
                  <span class="svg-icon svg-icon-1 position-absolute ms-6">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.5"
                        x="17.0365"
                        y="15.1223"
                        width="8.15546"
                        height="2"
                        rx="1"
                        transform="rotate(45 17.0365 15.1223)"
                        fill="black"
                      ></rect>
                      <path
                        d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19
                              11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333
                              17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                        fill="black"
                      ></path>
                    </svg>
                  </span>
                  <input
                    type="text"
                    v-model="search"
                    :placeholder="$t('synonyms.list.table.search_field')"
                    class="form-control form-control-solid w-250px ps-14"
                  />
                </div>
              </template>
              <template v-slot:item.actions="{ item }">
                <a
                  @click="editItem(item)"
                  href="#"
                  class="btn btn-primary font-weight-bolder mr-2"
                >
                  {{ $t("common.edit") }}</a
                >
                <a
                  @click="deleteItem(item)"
                  href="#"
                  class="btn btn-danger font-weight-bolder"
                >
                  {{ $t("common.delete") }}</a
                >
              </template>
              <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">
                  {{ $t("common.nodata") }}
                </v-btn>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import edit_dialog from "./edit_dialog";
import { mapGetters, mapState } from "vuex";
import {
  DELETE_SYNONYM,
  PULL_SYNONYM,
  PULL_SYNONYMS
} from "../../../core/services/store/synonyms.module";
import Swal from "sweetalert2";

export default {
  components: { edit_dialog },
  data() {
    return {
      search: "",
      options: {},
      headers: [
        {
          text: this.$t("synonyms.list.table.headers.catalog_term"),
          value: "catalog_term"
        },
        { text: this.$t("synonyms.list.table.headers.terms"), value: "terms" },
        {
          text: this.$t("synonyms.list.table.headers.synonym_type_id"),
          value: "synonym_type_id"
        },
        {
          text: this.$t("synonyms.list.table.headers.actions"),
          value: "actions",
          sortable: false
        }
      ]
    };
  },
  watch: {
    changeProject() {
      this.reloadData();
    }
  },
  computed: {
    changeProject() {
      return this.currentProject.token;
    },
    ...mapState({
      errors: state => state.synonyms.errors,
      message: state => state.synonyms.message,
      hasMessage: state => state.synonyms.hasMessage,
      items: state => state.synonyms.synonyms_list,
      loading: state => state.synonyms.loading
    }),
    ...mapGetters(["currentProject"])
  },
  methods: {
    filterSearch(value, search, item) {
      return (
        value != null &&
        search != null &&
        this.synonymRender(item).indexOf(search) !== -1
      );
    },
    editItem(item) {
      this.$store.dispatch(PULL_SYNONYM, item.id);
    },
    arrayUnique(array) {
      var a = array.concat();
      for (var i = 0; i < a.length; ++i) {
        for (var j = i + 1; j < a.length; ++j) {
          if (a[i] === a[j]) a.splice(j--, 1);
        }
      }

      return a;
    },
    synonymRender(item) {
      switch (item.synonym_type_id) {
        case "1":
          return this.arrayUnique(item.synonyms.concat(item.term)).join();
        case "2":
          return item.synonyms.join() + " => " + item.term;
        case "3":
          return item.term + " => " + item.synonyms.join();
      }
    },
    deleteItem(item) {
      Swal.fire({
        title: this.$t("common.dialog_delete.title"),
        text: this.$t("common.dialog_delete.content"),
        cancelButtonText: this.$t("common.dialog_delete.cancel"),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("common.dialog_delete.confirm")
      }).then(result => {
        if (result.value) {
          this.$store.dispatch(DELETE_SYNONYM, item.id).then(() => {
            this.reloadData();
            Swal.fire(this.$t("common.dialog_delete.success"), "", "success");
          });
        }
      });
    },
    reloadData() {
      this.$store.dispatch(PULL_SYNONYMS);
    }
  },
  mounted() {
    this.reloadData();
  }
};
</script>
